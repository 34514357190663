import { Controller } from "@hotwired/stimulus";
import { dynamicBundleReplaceListingsPath } from "../../routes";
import {
  dynamicBundlesClearBorder,
  dynamicBundlesUpdateMainImage
} from "../../utils/dynamicBundles";

export default class extends Controller {
  static values = {
    swatchImage: String,
    active: Boolean,
    swatchIndex: Number,
    index: Number,
    listingIndex: Number,
    partIds: Array,
    listingId: Number,
    initialLoad: Boolean,
    dynamicBundleSlug: String,
    storeCode: String
  };

  connect() {
    if (!this.initialLoadValue && this.activeValue) {
      this.handleSwatchChange();
    }
  }

  handleSwatchChange() {
    dynamicBundlesClearBorder();
    this.updateMainFormAction();
    this.addBorderToCurrentIndex();
    this.swapMainImage();
  }

  addBorderToCurrentIndex() {
    const listingElement = document.getElementById(
      `related-listings-listing-${this.listingIndexValue}`
    );

    const labelElement = listingElement.querySelector(".product-image");

    labelElement.classList.add("border-ink");
    labelElement.classList.remove("border-transparent");
  }

  swapMainImage() {
    const mainImageContainer = document.getElementById(
      "dynamic-bundle-main-image-container"
    );

    dynamicBundlesUpdateMainImage(
      mainImageContainer,
      this.listingIndexValue,
      this.swatchImageValue
    );
  }

  updateMainFormAction() {
    const newPartIds = [...this.partIdsValue];
    newPartIds[this.indexValue] = this.listingIdValue;

    const form = document.getElementById("related-listings-form");

    form.action = dynamicBundleReplaceListingsPath({
      dynamic_bundle_slug: this.dynamicBundleSlugValue,
      bundleable_ids: newPartIds.join("/"),
      store: this.storeCodeValue
    });
  }

  mouseOver(event) {
    if (this.activeValue) return;

    const imageContainer = event.target
      .closest(".product-listing")
      .querySelector(".product-image");

    const imageElement = new Image();
    imageElement.src = this.swatchImageValue;
    imageElement.dataset.index = this.swatchIndexValue;
    imageElement.setAttribute(
      "class",
      "img-responsive w-full cursor-pointer aspect-ratio-1x1 absolute opacity-0 dynamic-bundle-image"
    );
    imageElement.setAttribute(
      "data-action",
      "click->dynamic-bundle#handleOptionChange"
    );

    imageContainer.appendChild(imageElement);
    imageElement.onload = () => {
      imageElement.classList.remove("opacity-0");
    };
  }

  mouseOut(event) {
    if (this.activeValue) return;

    const indexToRemove = this.swatchIndexValue;
    const productImageContainer = event.target
      .closest(".product-listing")
      .querySelector(".product-image");
    const imageToRemove = productImageContainer.querySelector(
      `[data-index="${indexToRemove}"]`
    );

    if (imageToRemove) {
      imageToRemove.classList.add("opacity-0", "pointer-events-none");
    }

    setTimeout(() => {
      const allImagesMatchingIndex = productImageContainer.querySelectorAll(
        `[data-index="${indexToRemove}"]`
      );
      allImagesMatchingIndex.forEach(image => {
        image.remove();
      });
    }, 100);
  }
}
